import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: () => {
      if (localStorage.getItem('JWT') !== null && localStorage.getItem('JWT') !== '') {
        return { path: '/stundentool' };
      } else {
        return { path: '/login' };
      }
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/stundentool',
    name: 'Stundentool',
    component: () => import('../views/Stundentool.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('JWT') !== null && localStorage.getItem('JWT') !== '') {
        next();
      } else {
        next({ name: 'Login' });
      }
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('JWT') !== null && localStorage.getItem('JWT') !== '') {
        next();
      } else {
        next({ name: 'Login' });
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
