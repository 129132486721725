<template>
  <div class="stundentool__notification" :class="typeClass">
    {{ notification.msg }}
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  computed: {
    ...mapState(['notification']),
    typeClass() {
      return `stundentool__notification--${this.notification.type}`;
    },
  },
  created() {
    setTimeout(() => {
      this.REMOVE_NOTIFICATION();
    }, 5000);
  },
  methods: {
    ...mapActions(['REMOVE_NOTIFICATION']),
  },
};
</script>
