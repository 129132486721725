<template>
  <div id="app">
    <Header />
    <router-view />
    <app-notification v-if="successRequest"></app-notification>
  </div>
</template>

<script>
import Header from './components/layout/Header.vue';
import Notification from './components/notification/Notification.vue';
import { mapState } from 'vuex';

export default {
  components: {
    Header,
    'app-notification': Notification,
  },
  computed: {
    ...mapState(['successRequest']),
  },
};
</script>
