<template>
  <mdb-navbar v-if="accessToken" color="elegant-color-dark" dark>
    <mdb-navbar-brand href="https://mdbootstrap.com/" class="border-right">
      Fliesenleger – Lamprecht
    </mdb-navbar-brand>
    <mdb-navbar-toggler>
      <mdb-navbar-nav>
        <mdb-nav-item class="align-self-center"
          ><router-link class="link grey-text" to="/stundentool"
            >Stundentool</router-link
          ></mdb-nav-item
        >
        <mdb-nav-item class="align-self-center" v-if="admin"
          ><router-link class="link grey-text" to="/admin">Admin</router-link></mdb-nav-item
        >
        <mdb-nav-item class="mx-auto" @click="logout()">
          <span>
            <mdb-btn class="button p-2" color="danger">Abmelden</mdb-btn>
          </span>
        </mdb-nav-item>
      </mdb-navbar-nav>
    </mdb-navbar-toggler>
  </mdb-navbar>
</template>

<script>
import {
  mdbNavbar,
  mdbNavbarBrand,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbNavItem,
  mdbBtn,
} from 'mdbvue';
import { mapState } from 'vuex';

export default {
  name: 'Header',
  components: {
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavItem,
    mdbBtn,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['accessToken', 'admin']),
  },
  methods: {
    logout() {
      this.$store.dispatch('LOGOUT_REQUEST').then(() => {
        this.$router.replace({ name: 'Login' });
      });
    },
  },
};
</script>
