import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  // todo successRequest to showNotification -> need errorRequest
  state: {
    apiDomain: 'https://stundentool.fliesen-lamprecht.de/api',
    // apiDomain: 'http://localhost:3333',
    status: '',
    users: '',
    admin: false,
    accessToken: null,
    refreshToken: null,
    projects: null,
    activeProjects: [],
    displayedData: null,
    successRequest: false,
    notification: {
      type: null,
      msg: null,
    },
    allProjectEntries: [],
    allWorkingTime: [],
    projectEntriesForPaper: [],
    workingTimeForPaper: [],
  },

  getters: {
    accessToken: (state) => {
      return state.accessToken;
    },
    users: (state) => {
      return state.users;
    },
    refreshToken: (state) => {
      return state.refreshToken;
    },
    displayedData: (state) => {
      return state.displayedData;
    },
    projects: (state) => {
      return state.projects;
    },
    activeProjects: (state) => {
      return state.activeProjects;
    },
    noFeatProjects: (state) => {
      let allProjects = state.projects;
      return allProjects.filter((project) => project.feat === null || project.feat === '');
    },
    allProjectEntries: (state) => {
      return state.allProjectEntries;
    },
    allWorkingTime: (state) => {
      return state.allWorkingTime;
    },
    projectEntriesForPaper: (state) => {
      return state.projectEntriesForPaper;
    },
    workingTimeForPaper: (state) => {
      return state.workingTimeForPaper;
    },
  },

  mutations: {
    AUTH_REQUEST(state) {
      state.status = 'loading';
    },
    SAVE_USERS(state, user) {
      console.log(user);
      // state.users = user;
    },
    AUTH_SUCCESS(state, tokens) {
      localStorage.removeItem('JWT');
      localStorage.setItem('JWT', tokens.refreshToken);
      state.admin = tokens.admin;
      state.accessToken = tokens.accessToken;
      state.refreshToken = tokens.refreshToken;
      state.status = 'success';
    },
    AUTH_ERROR(state) {
      state.status = 'error';
      localStorage.removeItem('JWT');
    },
    LOGOUT(state) {
      state.accessToken = null;
      state.refreshToken = null;
      localStorage.setItem('JWT', '');
    },
    SET_PROJECTS(state, projects) {
      state.projects = projects;
    },
    SET_ACTIVE_PROJECTS(state, projects) {
      state.activeProjects = [];
      projects.forEach((project) => {
        if (project.active === 1) {
          state.activeProjects.push(project);
        }
      });
    },
    SET_WEEK_DATA(state, weekData) {
      state.displayedData = weekData;
    },
    SUCCESS_REQUEST(state, notification) {
      state.successRequest = true;
      state.notification = notification;
    },
    RESET_NOTIFICATION(state) {
      state.successRequest = false;
      state.notification = {
        type: null,
        msg: null,
      };
    },
    SET_ALL_PROJECT_ENTRIES(state, projectEntries) {
      state.allProjectEntries = projectEntries;
    },
    SET_ALL_WORKING_TIME(state, workingTime) {
      state.allWorkingTime = workingTime;
    },
    SAVE_PROJECT_ENTRIES_FOR_PAPER(state, paperProjectEntries) {
      state.projectEntriesForPaper = paperProjectEntries;
    },
    SAVE_WORKING_TIME_FOR_PAPER(state, paperWorkingTime) {
      state.workingTimeForPaper = paperWorkingTime;
    },
  },

  actions: {
    /**
     * Notification actions
     */
    REMOVE_NOTIFICATION({ commit }) {
      commit('RESET_NOTIFICATION');
    },
    /**
     * User Handling
     */
    // ALL_USER({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     axios({
    //       url: this.state.apiDomain + '/all-user',
    //       headers: { Authorization: `Bearer ${this.state.accessToken}` },
    //       method: 'POST',
    //     })
    //       .then((resp) => {
    //         commit('SAVE_USERS');
    //         resolve(resp);
    //       })
    //       .catch((err) => {
    //         commit('AUTH_ERROR', err);
    //         reject(err);
    //       });
    //   });
    // },
    /**
     * Auth actions
     */
    AUTH_REQUEST({ commit, dispatch }, user) {
      return new Promise((resolve, reject) => {
        commit('AUTH_REQUEST');
        axios({
          url: this.state.apiDomain + '/login',
          data: user,
          method: 'POST',
        })
          .then((resp) => {
            const tokens = {
              accessToken: resp.data.accessToken,
              refreshToken: resp.data.refreshToken,
              admin: resp.data.admin,
            };
            commit('AUTH_SUCCESS', tokens);
            dispatch('AUTO_REFRESH', resp.data.refreshToken);
            resolve(resp);
          })
          .catch((err) => {
            commit('AUTH_ERROR', err);
            reject(err);
          });
      });
    },
    AUTO_REFRESH({ dispatch }, refreshToken) {
      const expireTime = 15 * 60000 - 30000;
      // const expireTime = 15000;
      setTimeout(() => {
        dispatch('REFRESH_TOKEN', refreshToken);
      }, expireTime);
    },
    REFRESH_TOKEN({ commit, dispatch }, refreshToken) {
      return new Promise((resolve, reject) => {
        const token = { token: refreshToken };
        axios({
          url: this.state.apiDomain + '/refresh-token',
          data: token,
          method: 'POST',
        })
          .then((resp) => {
            const tokens = {
              accessToken: resp.data.accessToken,
              refreshToken: refreshToken,
              admin: resp.data.admin,
            };
            commit('AUTH_SUCCESS', tokens);
            dispatch('AUTO_REFRESH', refreshToken);
            resolve(resp);
          })
          .catch((err) => {
            commit('AUTH_ERROR', err);
            reject(err);
          });
      });
    },
    LOGOUT_REQUEST({ commit }) {
      return new Promise((resolve, reject) => {
        const token = { token: this.state.refreshToken };
        axios({
          url: this.state.apiDomain + '/logout',
          data: token,
          method: 'DELETE',
        })
          .then((resp) => {
            commit('LOGOUT');
            resolve(resp);
          })
          .catch((err) => {
            commit('AUTH_ERROR', err);
            reject(err);
          });
      });
    },
    /**
     * Initial loading actions
     */
    GET_PROJECTS({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: this.state.apiDomain + '/project/get-all',
          headers: { Authorization: `Bearer ${this.state.accessToken}` },
          method: 'POST',
        })
          .then((resp) => {
            commit('SET_PROJECTS', resp.data);
            commit('SET_ACTIVE_PROJECTS', resp.data);
            resolve(resp);
          })
          .catch((err) => {
            commit('AUTH_ERROR', err);
            reject(err);
          });
      });
    },
    GET_WEEK_DATA({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: this.state.apiDomain + '/project-entry/get-week-entries',
          data,
          headers: { Authorization: `Bearer ${this.state.accessToken}` },
          method: 'POST',
        })
          .then((resp) => {
            commit('SET_WEEK_DATA', resp.data);
            resolve(resp);
          })
          .catch((err) => {
            commit('AUTH_ERROR', err);
            reject(err);
          });
      });
    },
    /**
     * Week entry handling
     */
    ADD_DAY_ENTRY({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: this.state.apiDomain + '/project-entry/add',
          data: {
            week: data.week,
            day: data.day,
            project: data.project,
            hours: data.hours,
            productive: data.productive,
            unproductive: data.unproductive,
            year: data.year,
          },
          headers: { Authorization: `Bearer ${this.state.accessToken}` },
          method: 'POST',
        })
          .then((resp) => {
            commit('SUCCESS_REQUEST', {
              type: 'success',
              msg: `${data.hours}h ${data.project} am ${data.day} hinzugefügt!`,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    UPDATE_DAY_ENTRY({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: this.state.apiDomain + '/project-entry/edit',
          data: {
            id: data.id,
            project: data.project,
            hours: data.hours,
            productive: data.productive,
            unproductive: data.unproductive,
            year: data.year,
          },
          headers: { Authorization: `Bearer ${this.state.accessToken}` },
          method: 'POST',
        })
          .then((resp) => {
            commit('SUCCESS_REQUEST', {
              type: 'success',
              msg: 'Eintrag wurde erfolgreich bearbeitet!',
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    DELETE_DAY_ENTRY({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: this.state.apiDomain + '/project-entry/delete',
          data: {
            id: id,
          },
          headers: { Authorization: `Bearer ${this.state.accessToken}` },
          method: 'POST',
        })
          .then((resp) => {
            commit('SUCCESS_REQUEST', {
              type: 'success',
              msg: 'Eintrag wurde erfolgreich gelöscht!',
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GET_DAY_ENTRIES_BY_WEEKS({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: this.state.apiDomain + '/project-entry/get-weeks',
          data: {
            worker: data.worker,
            lowestWeekNumber: data.lowestWeek,
            highestWeekNumber: data.highestWeek,
            year: data.year,
          },
          headers: { Authorization: `Bearer ${this.state.accessToken}` },
          method: 'POST',
        })
          .then((resp) => {
            commit('SAVE_PROJECT_ENTRIES_FOR_PAPER', resp.data.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * Working time handling
     */
    ADD_WORKING_TIME({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: this.state.apiDomain + '/working-time/add',
          data: {
            week: data.week,
            day: data.day,
            startTime: data.startTime,
            endTime: data.endTime,
            break: data.break,
            total: data.total,
            year: data.year,
          },
          headers: { Authorization: `Bearer ${this.state.accessToken}` },
          method: 'POST',
        })
          .then((resp) => {
            commit('SUCCESS_REQUEST', {
              type: 'success',
              msg: 'Start- und Endzeit wurde erfolgreich hinzugefügt!',
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    UPDATE_WORKING_TIME({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: this.state.apiDomain + '/working-time/edit',
          data: {
            id: data.id,
            startTime: data.startTime,
            endTime: data.endTime,
            break: data.break,
            total: data.total,
            year: data.year,
          },
          headers: { Authorization: `Bearer ${this.state.accessToken}` },
          method: 'POST',
        })
          .then((resp) => {
            commit('SUCCESS_REQUEST', {
              type: 'success',
              msg: 'Start- und Endzeit wurde erfolgreich bearbeitet!',
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    DELETE_WORKING_TIME({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: this.state.apiDomain + '/working-time/delete',
          data: {
            id: id,
          },
          headers: { Authorization: `Bearer ${this.state.accessToken}` },
          method: 'POST',
        })
          .then((resp) => {
            commit('SUCCESS_REQUEST', {
              type: 'success',
              msg: 'Start- und Endzeit wurde erfolgreich gelöscht!',
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GET_WORKING_TIME_BY_WEEKS({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: this.state.apiDomain + '/working-time/get-weeks',
          data: {
            worker: data.worker,
            lowestWeekNumber: data.lowestWeek,
            highestWeekNumber: data.highestWeek,
            year: data.year,
          },
          headers: { Authorization: `Bearer ${this.state.accessToken}` },
          method: 'POST',
        })
          .then((resp) => {
            commit('SAVE_WORKING_TIME_FOR_PAPER', resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * project handling
     */
    ADD_PROJECT({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: this.state.apiDomain + '/project/add',
          data: {
            name: data.name,
            productive: data.productive,
            unproductive: data.unproductive,
            feat: data.feat,
            active: data.active,
          },
          headers: { Authorization: `Bearer ${this.state.accessToken}` },
          method: 'POST',
        })
          .then((resp) => {
            commit('SUCCESS_REQUEST', {
              type: 'success',
              msg: 'Projekt wurde erfolgreich hinzugefügt!',
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    DELETE_PROJECT({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: this.state.apiDomain + '/project/delete',
          data: {
            id: id,
          },
          headers: { Authorization: `Bearer ${this.state.accessToken}` },
          method: 'DELETE',
        })
          .then((resp) => {
            commit('SUCCESS_REQUEST', {
              type: 'success',
              msg: 'Projekt wurde erfolgreich gelöscht!',
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    UPDATE_STATE_PROJECT({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: this.state.apiDomain + '/project/editState',
          data: {
            id: data.id,
            active: data.active,
          },
          headers: { Authorization: `Bearer ${this.state.accessToken}` },
          method: 'POST',
        })
          .then((resp) => {
            commit('SUCCESS_REQUEST', {
              type: 'success',
              msg: 'Projekt wurde erfolgreich inaktiv gesetzt!',
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * calculation handling
     */
    ADD_CALCULATION({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: this.state.apiDomain + '/calculation/add',
          data: {
            project: data.project,
            productiveEarn: data.productiveEarn,
            fullEarn: data.fullEarn,
            revenue: data.revenue,
            materialcosts: data.materialcosts,
            wagecosts: data.wagecosts,
          },
          headers: { Authorization: `Bearer ${this.state.accessToken}` },
          method: 'POST',
        })
          .then((resp) => {
            commit('SUCCESS_REQUEST', {
              type: 'success',
              msg: 'Kalkulation wurde erfolgreich hinzugefügt!',
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GET_HOURS({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: this.state.apiDomain + '/user/worked-hours',
          data: {
            worker: data.worker,
            project: data.project,
          },
          headers: { Authorization: `Bearer ${this.state.accessToken}` },
          method: 'POST',
        })
          .then((resp) => {
            commit('SUCCESS_REQUEST', {
              type: 'success',
              msg: 'Stunden erfolgreich geladen!',
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * FOR WORKING-HOUR-PAPER
     */
    // GET_ALL_PROJECT_ENTRIES({ commit }, data) {
    //   return new Promise((resolve, reject) => {
    //     axios({
    //       url: this.state.apiDomain + '/project-entry/get-all',
    //       data: {
    //         user: data,
    //       },
    //       headers: { Authorization: `Bearer ${this.state.accessToken}` },
    //       method: 'POST',
    //     })
    //       .then((resp) => {
    //         commit('SET_ALL_PROJECT_ENTRIES', resp.data);
    //         resolve(resp);
    //       })
    //       .catch((err) => {
    //         commit('AUTH_ERROR', err);
    //         reject(err);
    //       });
    //   });
    // },
    // GET_ALL_WORKING_TIME({ commit }, data) {
    //   return new Promise((resolve, reject) => {
    //     axios({
    //       url: this.state.apiDomain + '/working-time/get-all',
    //       data: {
    //         user: data,
    //       },
    //       headers: { Authorization: `Bearer ${this.state.accessToken}` },
    //       method: 'POST',
    //     })
    //       .then((resp) => {
    //         commit('SET_ALL_WORKING_TIME', resp.data);
    //         resolve(resp);
    //       })
    //       .catch((err) => {
    //         commit('AUTH_ERROR', err);
    //         reject(err);
    //       });
    //   });
    // },
  },
});
